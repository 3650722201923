import blogs01 from '../screens/jobseeker/Blogs/blogs01.svg'
import blogs02 from '../screens/jobseeker/Blogs/blogs02.svg'
import blogs03 from '../screens/jobseeker/Blogs/blogs03.svg'
import Progressbar from "../screens/jobseeker/Progressbar/Progressbar"


const BlogsData = [
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs02,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'How to use FMN',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs03,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Career Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs03,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'How to use FMN',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs02,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Career Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs02,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'How to use FMN',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs02,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'How to use FMN',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs02,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'How to use FMN',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },

    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },
    {
        image:blogs01,
        progressbar:<Progressbar/>,
        date : 'Date: 21 July 2022',
        Heading:'Interview Advice',
        content:'Lorem Ipsum is simply dummy textof the printing and typesetting industry. Lorem Ipsum has bee the industry standard',
    },

]

export default BlogsData;