const Data = [
    {
        head:"Jyoti",
        headmsg:"Ranjan  lorem lorem lorem lorem lorem lorem lorem lorem lorem",
        subHead:"Kalta",
        submsg:"lorem lorem lorem lorem lo lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
        bodymsg:" lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem"
    }

]

export default Data;