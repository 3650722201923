export const LOADING = "LOADING";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_SIGNUP = "AUTH_SIGNUP";
export const GLOBLE_LOADIING = "GLOBLE_LOADIING";
export const GET_USER = "GET_USER";
export const GET_SCOPE = "GET_SCOPE";
export const GET_AVILABLEJOB = "GET_AVILABLEJOB";
export const GET_SAVEDJOB = "GET_SAVEDJOB";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_CATEGORYTYPE = "GET_CATEGORYTYPE";
export const GET_INDUSTRYTYPE = "GET_INDUSTRYTYPE";
export const GET_CITIES = "GET_CITIES";
export const GET_BLOG_POST = "GET_BLOG_POST";
export const GET_STORY = "GET_STORY";
export const UPDATE_BLOG_POST = "UPDATE_BLOG_POST";
export const GET_MYCREATED_POST = "GET_MYCREATED_POST";
export const GET_ALLPOST = "GET_ALLPOST";
export const GET_PLANS = "GET_PLANS";
export const ACTIVITY_TABLE = "ACTIVITY_TABLE";
export const TEMPLATE_QUESITIONS = "TEMPLATE_QUESITIONS";
export const APPLIED_USER_DATA = "APPLIED_USER_DATA";
export const GET_APPLIEDJOB = "GET_APPLIEDJOB";
export const GET_SUBSCRIBATION = "GET_SUBSCRIBATION";
export const GET_CREDIT = "GET_CREDIT";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_FREELANCEING = "GET_FREELANCEING";
export const COMPLETE_PROFILE = "COMPLETE_PROFILE";
