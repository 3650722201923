const Saved=[
    {
        Job:'Fullstack Developer',
        Company:'Tech Jain It Solution',
        JobType:'Work From Home',
        DateApplied:'Applied on 1 May 2022',
        NumberAplid:'Around 10 people applied for position'
    },
    {
        Job:'Fullstack Developer',
        Company:'Tech Jain It Solution',
        JobType:'Work From Home',
        DateApplied:'Applied on 1 May 2022',
        NumberAplid:'Around 10 people applied for position'
    },
    {
        Job:'Fullstack Developer',
        Company:'Tech Jain It Solution',
        JobType:'Work From Home',
        DateApplied:'Applied on 1 May 2022',
        NumberAplid:'Around 10 people applied for position'
    },
]
export default Saved;